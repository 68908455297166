<template>
    <div>
        <main-button
            :on-click="openPopup"
            :small=true
            glow
        >
            Buy
        </main-button>
        <dialog-box
            v-if="isWalletConnected"
            :model="popupOpen"
            :title="`Buy ${land.name}`"
            width="700"
            v-on:dialogClosed="popupOpen=!popupOpen"
        >
            <v-container>
                <v-row class="mt-1">
                    <v-alert
                        v-if="land.status === 'AVAILABLE'"
                        class="w-100"
                        dense
                        outlined
                        text
                        type="success"
                    >
                        Land is available for sale.
                    </v-alert>
                    <v-alert
                        v-else-if="land.status === 'RESERVED'"
                        class="w-100"
                        dense
                        outlined
                        text
                        type="warning"
                    >
                        Land is currently reserved by someone else.
                    </v-alert>
                    <v-alert
                        v-else-if="land.status === 'OWNED'"
                        class="w-100"
                        dense
                        outlined
                        text
                        type="error"
                    >
                        Land is already claimed.
                    </v-alert>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        sm="5"
                    >
                        <v-row class="mb-4 d-flex justify-center justify-sm-start">
                            <mini-land
                                :item="land"
                                popup-disabled
                            ></mini-land>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="7"
                    >
                        <v-row class="section-details pt-2 mb-4 d-flex justify-center justify-sm-end">
                            <v-col cols="3">
                                <v-row>
                                    <p>Type: </p>
                                </v-row>
                                <v-row>
                                    <p>Coords: </p>
                                </v-row>
                                <v-row>
                                    <p>Element: </p>
                                </v-row>
                                <v-row>
                                    <p>Rarity: </p>
                                </v-row>
                                <v-row>
                                    <p>Power: </p>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row>
                                    <p><span>{{ land.typeName }}</span></p>
                                </v-row>
                                <v-row>
                                    <p><span>{{ land.pos_x }}x{{ land.pos_y }}</span></p>
                                </v-row>
                                <v-row>
                                    <p>
                                    <span :class="'text-element-'+land.element||''" class="text-capitalize"><v-icon
                                        small>mdi-{{ elementIcon }}</v-icon> {{ land.element }} </span>
                                    </p>
                                </v-row>
                                <v-row>
                                    <p>
                                        <rarity-text :rarity="land.rarity"></rarity-text>
                                    </p>
                                </v-row>
                                <v-row>
                                    <p><span v-if="land.type">{{ land.description }}</span></p>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="text-center">
                        <h3>Bonus gifts</h3>
                        <v-row class="bonus-enhancements">
                            <v-col cols="4">
                                <h4 class="mb-2">3x</h4>
                                <img src="https://cdn.wizarre.io/img/e/150/0.png" alt="Ephemeral Wizard"
                                     title="3x Ephemeral Wizard" v-title/>
                            </v-col>
                            <v-col cols="4">
                                <h4 class="mb-2">6x</h4>
                                <img src="https://cdn.wizarre.io/img/e/150/27.png" alt="Yummy Yummy"
                                     title="6x Yummy Yummy" v-title/>
                            </v-col>
                            <v-col cols="4">
                                <h4 class="mb-2">9x</h4>
                                <img src="https://cdn.wizarre.io/img/e/150/26.png" alt="Jumplegs" title="9x Jumplegs"
                                     v-title/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="messages.value.length === 0 && ['AVAILABLE'].includes(land.status)">
                    <v-col class="hidden-xs-only" cols="1">
                        <v-row class="price-row d-flex align-center">
                            <v-img aspect-ratio="1" class="mr-2" max-width="24" src="/img/scroll_48.png"
                                   title="Wizarre SCRL" v-title/>
                        </v-row>
                        <v-row class="price-row d-flex align-center">
                            <v-img aspect-ratio="1" class="mr-2" max-width="24" src="/img/tokens/usdt_48.png"
                                   title="USDT" v-title/>
                        </v-row>
                    </v-col>
                    <v-col cols="5" sm="3">
                        <v-row class="price-row d-flex align-center justify-end">
                            <h4 class="mr-2">{{ land?.landPrice?.scrl.toLocaleString() }}</h4>
                        </v-row>
                        <v-row class="price-row d-flex align-center justify-end">
                            <h4 class="mr-2">{{ land?.landPrice?.usdt.toLocaleString() }}</h4>
                        </v-row>
                    </v-col>
                    <v-col cols="3" sm="2">
                        <v-row class="price-row d-flex align-center">
                            <h4>SCRL</h4>
                        </v-row>
                        <v-row class="price-row d-flex align-center">
                            <h4>USDT</h4>
                        </v-row>
                    </v-col>
                    <v-col cols="3" sm="6">
                        <v-row class="price-row d-flex align-center">
                            <main-button
                                :on-click="buyForSCRL"
                                :loading="buyInProgress"
                                :disabled="!hasBalanceToBuyInSCRL"
                                :title="hasBalanceToBuyInSCRL?'':messages.notEnoughSCRL"
                                v-title
                            >
                                Buy
                            </main-button>
                        </v-row>

                        <v-row class="price-row d-flex align-center">
                            <main-button
                                :on-click="buyForUSDT"
                                :loading="buyInProgress"
                                :disabled="!hasBalanceToBuyInUSDT"
                                :title="hasBalanceToBuyInUSDT?'':messages.notEnoughUSDT"
                                v-title
                            >
                                Buy
                            </main-button>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row
                    v-if="messages.value.length > 0">
                    <v-alert
                        :type="messages.type"
                        class="w-100 mt-5"
                        dense
                        outlined
                        text
                    >
                        {{ messages.value }}
                        <v-progress-linear
                            v-if="messages.type === 'info'"
                            color="blue"
                            indeterminate
                        >
                        </v-progress-linear>
                        <v-btn
                            v-if="messages.type === 'error'"
                            class="mt-2 d-block"
                            color="red"
                            @click="openPopup"
                        >
                            Retry
                        </v-btn>
                    </v-alert>
                </v-row>
            </v-container>
        </dialog-box>
        <dialog-box
            v-else-if="!isWalletConnected"
            :model="popupOpen"
            :title="`Buy ${land.name}`"
            width="1000"
            v-on:dialogClosed="popupOpen=!popupOpen"
        >
            <connect-wallet disableImage></connect-wallet>
        </dialog-box>
    </div>
</template>

<script>
import MainButton from "@/components/ui/main-button";
import DialogBox from "@/components/dialog-box";
import MiniLand from "@/components/assets/lands/mini-land";
import RarityText from "@/components/assets/lands/rarity-text";
import ConnectWallet from "@/views/ConnectWallet";
import LandSaleContract from "@/services/contracts/landSaleContract";
import {addDecimals} from "@/helpers/tokenHelper";
import UsdtTokenContract from "@/services/contracts/usdtTokenContract";

const elementIcon = {
    fire: 'fire',
    water: 'water',
    nature: 'leaf',
}
const allowanceMargin = 1;

export default {
    components: {ConnectWallet, RarityText, MiniLand, DialogBox, MainButton},
    props: {
        land: Object,
    },
    data() {
        return {
            popupOpen: false,
            messages: {
                value: '',
                type: 'info',
                checkingTokenAllowance: 'Checking token allowance',
                waitingForAllowanceConfirmation: 'Waiting for allowance confirmation',
                allowanceConfirmationError: 'There was error when setting allowance',
                waitingForTransactionAcceptance: 'Waiting for transaction acceptance',
                transactionAcceptanceError: 'There was error with transaction',
                landPurchaseConfirmed: 'Land purchase confirmed',
                notEnoughUSDT: 'You have not enough USDT',
                notEnoughSCRL: 'You have not enough SCRL',
            },
            buyInProgress: false,
            userBalanceUsdt: 0,
        }
    },
    mounted() {
        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    destroyed() {
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        elementIcon() {
            return elementIcon[this.land.element];
        },
        hasBalanceToBuyInUSDT() {
            return this.isWalletConnected && this.land?.landPrice?.usdt && this.userBalanceUsdt >= addDecimals(this.land.landPrice.usdt);
        },
        hasBalanceToBuyInSCRL() {
            return this.isWalletConnected && this.land?.landPrice?.scrl && this.web3.balance >= addDecimals(this.land.landPrice.scrl);
        },
    },
    methods: {
        init() {
            const usdtTokenContract = new UsdtTokenContract(this.web3);
            usdtTokenContract.getCurrentAccountBalance().then((balance) => {
                this.userBalanceUsdt = balance;
            });
        },
        openPopup() {
            this.messages.value = '';
            this.messages.type = 'info';
            this.buyInProgress = false;
            this.popupOpen = true;
        },
        async buyForSCRL() {
            this.buyInProgress = true;
            const landSaleContract = new LandSaleContract(this.web3);

            this.messages.value = this.messages.checkingTokenAllowance;
            this.messages.type = 'info';
            const allowance = this.$options.filters.removeDecimals(Number(await landSaleContract.checkSCRLAllowance()));
            const isAllowanceSufficient = allowance >= this.land.landPrice.scrl;

            if (!isAllowanceSufficient) {
                this.messages.value = this.messages.waitingForAllowanceConfirmation;
                this.messages.type = 'info';
                const desiredAllowance = Math.round(this.land.landPrice.scrl * allowanceMargin);
                const approveStatus = await landSaleContract.setSCRLAllowance(desiredAllowance).catch(() => {
                    this.messages.value = this.messages.allowanceConfirmationError;
                    this.messages.type = 'error';
                });

                if (!approveStatus) {
                    this.messages.value = this.messages.allowanceConfirmationError;
                    this.messages.type = 'error';
                    throw new Error(this.messages.allowanceConfirmationError);
                }
            }

            this.messages.value = this.messages.waitingForTransactionAcceptance;
            this.messages.type = 'info';

            const ok = await landSaleContract.buyLandForSCRL(this.land.id).catch(() => {
                this.messages.value = this.messages.transactionAcceptanceError;
                this.messages.type = 'error';
            });

            if (ok) {
                this.messages.value = this.messages.landPurchaseConfirmed;
                this.messages.type = 'success';
            } else {
                this.messages.value = this.messages.transactionAcceptanceError;
                this.messages.type = 'error';
            }
        },
        async buyForUSDT() {
            this.buyInProgress = true;
            const landSaleContract = new LandSaleContract(this.web3);

            this.messages.value = this.messages.checkingTokenAllowance;
            this.messages.type = 'info';
            const allowance = this.$options.filters.removeDecimals(Number(await landSaleContract.checkUSDTAllowance()));
            const isAllowanceSufficient = allowance >= this.land.landPrice.usdt;

            if (!isAllowanceSufficient) {
                this.messages.value = this.messages.waitingForAllowanceConfirmation;
                this.messages.type = 'info';
                const desiredAllowance = Math.round(this.land.landPrice.usdt);
                const approveStatus = await landSaleContract.setUSDTAllowance(desiredAllowance).catch(() => {
                    this.messages.value = this.messages.allowanceConfirmationError;
                    this.messages.type = 'error';
                });

                if (!approveStatus) {
                    this.messages.value = this.messages.allowanceConfirmationError;
                    this.messages.type = 'error';
                    throw new Error(this.messages.allowanceConfirmationError);
                }
            }

            this.messages.value = this.messages.waitingForTransactionAcceptance;
            this.messages.type = 'info';

            const ok = await landSaleContract.buyLandForUSDT(this.land.id).catch(() => {
                this.messages.value = this.messages.transactionAcceptanceError;
                this.messages.type = 'error';
            });

            if (ok) {
                this.messages.value = this.messages.landPurchaseConfirmed;
                this.messages.type = 'success';
            } else {
                this.messages.value = this.messages.transactionAcceptanceError;
                this.messages.type = 'error';

            }
        },
    },
    watch: {
        // land(value) {
        //     console.log(value)
        // }
    }

}
</script>

<style lang="scss" scoped>
h3 {
    color: #dabd81;
}

.bonus-enhancements {
    width: 80%;
    height: 150px;
    background: url("/img/profile/scroll.png") 100% 100% no-repeat;
    background-size: 100% 100%;
    margin: 10px 0 0 10%;
    padding: 0 40px;
    color: #000;

    img {
        width: 60px;
        aspect-ratio: 1;
    }
}

.section-details {
    p {
        color: var(--v-frames-base);
        margin-bottom: 5px;
    }

    span {
        color: white;
    }

    .text-element-neutral,
    .name.element-neutral {
        background-color: var(--v-secondary-base);
    }

    .text-element-water i,
    .text-element-water {
        color: #01a8ec;
    }

    .name.element-water {
        background-color: #01a8ec;
    }

    .text-element-fire i,
    .text-element-fire {
        color: #ec3c00;
    }

    .name.element-fire {
        background-color: #ec3c00;
    }

    .text-element-nature i,
    .text-element-nature {
        color: #77b000;
    }

    .name.element-nature {
        background-color: #77b000;
    }

}

.price-row {
    height: 50px;
    color: #fff;
}

@media only screen and (max-width: $xs) {
    .bonus-enhancements {
        width: 100%;
        margin: 10px 0 0 0;
        padding: 0;

        img {
            width: 40px;
        }
    }
}
</style>

<style lang="scss">
@media only screen and (max-width: $sm) {
    .v-dialog > .v-card > .v-card__text {
        padding: 0 10px 10px 10px;
    }
}

@media only screen and (max-width: $xs) {
    .v-dialog > .v-card > .v-card__text {
        padding: 0;
    }
    .v-card__text {
        padding: 0;
    }
}
</style>
