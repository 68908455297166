var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { attrs: { fluid: "" } }, [
    _c(
      "div",
      { staticClass: "page-content-map mb-15" },
      [
        _c(
          "v-row",
          {
            class: { "no-gutters": _vm.isSmallDesktopWidth },
            attrs: { dense: "" },
          },
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "8", lg: "8", "offset-lg": "1" } },
              [
                _c("div", { staticClass: "map-container d-flex" }, [
                  _c("canvas", {
                    class: { loading: _vm.mapLoading },
                    attrs: { id: "canvas", "data-paper-hidpi": "off" },
                  }),
                  _c("img", {
                    staticStyle: { display: "none" },
                    attrs: {
                      id: "map-img",
                      src: "/img/map/map-full_1700.webp",
                    },
                  }),
                  _vm.mapLoading
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "map-loading d-flex align-center justify-center",
                        },
                        [
                          _c("v-progress-circular", {
                            staticClass: "spinner",
                            attrs: { size: "200", indeterminate: "" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "map-frame-elem-l" }),
                  _c("div", { staticClass: "map-frame-elem-t" }),
                  _c("div", { staticClass: "map-frame-elem-r" }),
                  _c("div", { staticClass: "map-frame-elem-b" }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "map-frame-title d-flex align-center justify-center",
                    },
                    [_c("h2", [_vm._v("Wizarre world")])]
                  ),
                  _c("div", {
                    staticClass: "map-frame-wind-rose hidden-xs-only",
                  }),
                  _vm.isDebugEnv
                    ? _c(
                        "div",
                        {
                          staticClass: "ml-5 mt-5 pos-absolute",
                          staticStyle: { color: "#fff" },
                        },
                        [
                          _vm._v(" *fps: "),
                          _c("span", { attrs: { id: "fps" } }),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "map-stats hidden-sm-and-down" }, [
                    _vm.isDebugEnv
                      ? _c("div", [
                          _vm._v("*Visible: " + _vm._s(_vm.stats.hexCounter)),
                        ])
                      : _vm._e(),
                    _vm.isDebugEnv
                      ? _c("div", [
                          _vm._v("*Claimed: " + _vm._s(_vm.stats.claimed)),
                        ])
                      : _vm._e(),
                    _c("div", [_vm._v("Yours: " + _vm._s(_vm.stats.own))]),
                  ]),
                  _vm.landsRemainAmount && _vm.landsRemainAmount > 0
                    ? _c(
                        "div",
                        { staticClass: "box-remaining hidden-sm-and-down" },
                        [
                          _c("v-img", {
                            staticClass:
                              "head-icon hidden-md-and-down mt-5 ml-5 pull-left",
                            attrs: {
                              src: "/img/map/elem/wizard-head-green.png",
                              width: "28%",
                              "aspect-ratio": 74 / 116,
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "head-text ml-0 ml-md-3 ml-xl-6 pull-left",
                            },
                            [
                              _vm._v(" Only "),
                              _c("b", [
                                _vm._v(_vm._s(_vm.landsRemainAmount) + "/100"),
                              ]),
                              _c("br"),
                              _vm._v(" lands remain "),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
            _c(
              "v-col",
              {
                class: {
                  "col-auto": _vm.isSmallDesktopWidth,
                  "col-lg-2": !_vm.isSmallDesktopWidth,
                },
                attrs: { cols: "12", md: "auto", xl: "2" },
              },
              [
                _c(
                  "div",
                  { class: { row: _vm.$vuetify.breakpoint.smAndDown } },
                  [
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12",
                          sm: "5",
                          md: "12",
                          "offset-sm": "1",
                          "offset-md": "0",
                          order: "2",
                          "order-md": "1",
                        },
                      },
                      [
                        _c("div", { staticClass: "legend mt-5 mt-md-0" }, [
                          _c("h3", [_vm._v("Legend")]),
                          _c("div", [
                            _c("p", [_vm._v("Rarity")]),
                            _c("table", [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Rarity")]),
                                  _c("th", [_vm._v("Owned")]),
                                  _c("th", [_vm._v("Available")]),
                                ]),
                              ]),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [_vm._v("Yours")]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          style:
                                            "opacity:" +
                                            _vm.hexColor.opacity.claimed,
                                          attrs: { color: _vm.hexColor.owned },
                                        },
                                        [_vm._v(" mdi-hexagon ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          style:
                                            "opacity:" +
                                            _vm.hexColor.opacity.unclaimed,
                                          attrs: { color: _vm.hexColor.owned },
                                        },
                                        [_vm._v(" mdi-hexagon ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("tr", [
                                  _c("td", [_vm._v("Normal")]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          style:
                                            "opacity:" +
                                            _vm.hexColor.opacity.claimed,
                                          attrs: {
                                            color: _vm.hexColor.rarity.normal,
                                          },
                                        },
                                        [_vm._v("mdi-hexagon ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          style:
                                            "opacity:" +
                                            _vm.hexColor.opacity.unclaimed,
                                          attrs: {
                                            color: _vm.hexColor.rarity.normal,
                                          },
                                        },
                                        [_vm._v("mdi-hexagon ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("tr", [
                                  _c("td", [_vm._v("Rare")]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          style:
                                            "opacity:" +
                                            _vm.hexColor.opacity.claimed,
                                          attrs: {
                                            color: _vm.hexColor.rarity.rare,
                                          },
                                        },
                                        [_vm._v(" mdi-hexagon ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          style:
                                            "opacity:" +
                                            _vm.hexColor.opacity.unclaimed,
                                          attrs: {
                                            color: _vm.hexColor.rarity.rare,
                                          },
                                        },
                                        [_vm._v("mdi-hexagon ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("tr", [
                                  _c("td", [_vm._v("Epic")]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          style:
                                            "opacity:" +
                                            _vm.hexColor.opacity.claimed,
                                          attrs: {
                                            color: _vm.hexColor.rarity.epic,
                                          },
                                        },
                                        [_vm._v(" mdi-hexagon ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          style:
                                            "opacity:" +
                                            _vm.hexColor.opacity.unclaimed,
                                          attrs: {
                                            color: _vm.hexColor.rarity.epic,
                                          },
                                        },
                                        [_vm._v("mdi-hexagon ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12",
                          sm: "5",
                          md: "12",
                          order: "1",
                          "order-md": "2",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "details pos-relative mt-5" },
                          [
                            _vm.selectedLand &&
                            (_vm.selectedLand.status === "AVAILABLE" ||
                              _vm.selectedLand.status === "RESERVED")
                              ? _c(
                                  "div",
                                  { staticClass: "pos-absolute label-price" },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "mt-3",
                                        attrs: { "no-gutters": "" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "d-flex align-center",
                                            attrs: { cols: "5", offset: "1" },
                                          },
                                          [
                                            _c("v-img", {
                                              directives: [
                                                {
                                                  name: "title",
                                                  rawName: "v-title",
                                                },
                                              ],
                                              staticClass: "mr-1 ml-2",
                                              attrs: {
                                                "aspect-ratio": "1",
                                                width: "24",
                                                "max-width": "24",
                                                src: "/img/scroll_48.png",
                                                title: "Wizarre SCRL",
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedLand?.landPrice?.scrl.toLocaleString()
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "d-flex align-center",
                                            attrs: { cols: "5" },
                                          },
                                          [
                                            _c("v-img", {
                                              directives: [
                                                {
                                                  name: "title",
                                                  rawName: "v-title",
                                                },
                                              ],
                                              staticClass: "mr-1 ml-5",
                                              attrs: {
                                                "aspect-ratio": "1",
                                                width: "24",
                                                "max-width": "24",
                                                src: "/img/tokens/usdt_48.png",
                                                title: "USDT",
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedLand?.landPrice?.usdt.toLocaleString()
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "details-ball d-flex flex-column align-center pos-relative",
                              },
                              [
                                _vm.miniLandLoading
                                  ? _c("v-progress-circular", {
                                      staticClass: "mt-16",
                                      attrs: {
                                        indeterminate: "",
                                        size: 100,
                                        color: "amber",
                                      },
                                    })
                                  : _vm._e(),
                                !_vm.selectedLand && !_vm.miniLandLoading
                                  ? _c("div", [
                                      _c(
                                        "p",
                                        { staticClass: "details-prompt" },
                                        [_vm._v("Click on the map")]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.selectedLand
                                  ? _c("div", { staticClass: "w-100" }, [
                                      _c(
                                        "div",
                                        { staticClass: "mini-land-component" },
                                        [
                                          _vm.selectedLand.id
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Land",
                                                          params: {
                                                            id: _vm.selectedLand
                                                              .id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.selectedLand
                                                        ? _c("mini-land", {
                                                            attrs: {
                                                              item: _vm.selectedLand,
                                                              "popup-disabled":
                                                                "",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.selectedLand.id
                                            ? _c(
                                                "div",
                                                [
                                                  _vm.selectedLand
                                                    ? _c("mini-land", {
                                                        staticClass:
                                                          "mini-land-disabled",
                                                        attrs: {
                                                          item: _vm.selectedLand,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mini-land-text" },
                                        [
                                          _c("div", { staticClass: "name" }, [
                                            _vm._v(
                                              _vm._s(_vm.selectedLand.name)
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center justify-center",
                                            },
                                            [
                                              _c("icon-element", {
                                                attrs: {
                                                  element:
                                                    _vm.selectedLand.element,
                                                  "img-size": "18px",
                                                  inline: "",
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectedLand.typeName
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.clicked.x) +
                                                  "x" +
                                                  _vm._s(_vm.clicked.y) +
                                                  " | "
                                              ),
                                              _c("rarity-text", {
                                                attrs: {
                                                  rarity:
                                                    _vm.selectedLand.rarity,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mini-land-action d-flex flex-column justify-end",
                                        },
                                        [
                                          ["OWNED", "NOT_AVAILABLE"].includes(
                                            _vm.selectedLand.status
                                          )
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Land",
                                                      params: {
                                                        id: _vm.selectedLand.id,
                                                      },
                                                    },
                                                    small: "",
                                                  },
                                                },
                                                [_vm._v(" View ")]
                                              )
                                            : [
                                                "AVAILABLE",
                                                "RESERVED",
                                              ].includes(
                                                _vm.selectedLand.status
                                              )
                                            ? _c("land-sale-dialog", {
                                                attrs: {
                                                  land: _vm.selectedLand,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }