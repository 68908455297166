export default class QueryHelper {
    /**
     * @param {Object} params
     * @return void
     */
    static setQueryParamsInUrl(params) {
        try {
            const searchURL = new URL(window.location);
            for (const key of Object.keys(params)) {
                searchURL.searchParams.set(encodeURIComponent(key), encodeURIComponent(params[key]));
            }
            window.history.pushState({}, '', searchURL);
        } catch (e) {
            // do nothing
        }
    }

}
