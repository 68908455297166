<template>
    <v-container fluid>
        <div class="page-content-map mb-15">
            <v-row dense :class="{'no-gutters':isSmallDesktopWidth}">
                <v-col cols="12" md="8" lg="8" offset-lg="1">
                    <div class="map-container d-flex">
                        <canvas id="canvas" :class="{loading: mapLoading}" data-paper-hidpi="off"/>
                        <img id="map-img" style="display: none" src="/img/map/map-full_1700.webp"/>

                        <div v-if="mapLoading" class="map-loading d-flex align-center justify-center">
                            <v-progress-circular
                                size="200"
                                indeterminate
                                class="spinner"
                            >
                            </v-progress-circular>
                        </div>


                        <div class="map-frame-elem-l"></div>
                        <div class="map-frame-elem-t"></div>
                        <div class="map-frame-elem-r"></div>
                        <div class="map-frame-elem-b"></div>
                        <div class="map-frame-title d-flex align-center justify-center">
                            <h2>Wizarre world</h2>
                        </div>
                        <div class="map-frame-wind-rose hidden-xs-only"></div>

                        <div v-if="isDebugEnv" class="ml-5 mt-5 pos-absolute" style="color:#fff">
                            *fps: <span id="fps"></span>
                        </div>
                        <div class="map-stats hidden-sm-and-down">
                            <div v-if="isDebugEnv">*Visible: {{ stats.hexCounter }}</div>
                            <div v-if="isDebugEnv">*Claimed: {{ stats.claimed }}</div>
                            <div>Yours: {{ stats.own }}</div>
                        </div>

                        <div v-if="landsRemainAmount && landsRemainAmount>0" class="box-remaining hidden-sm-and-down">
                            <v-img
                                class="head-icon hidden-md-and-down mt-5 ml-5 pull-left"
                                src="/img/map/elem/wizard-head-green.png"
                                width="28%"
                                :aspect-ratio="74/116"
                            />
                            <div class="head-text ml-0 ml-md-3 ml-xl-6 pull-left">
                                Only <b>{{ landsRemainAmount }}/100</b>
                                <br/>
                                lands remain
                            </div>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" md="auto" :class="{'col-auto':isSmallDesktopWidth, 'col-lg-2':!isSmallDesktopWidth}"
                       xl="2">
                    <div :class="{'row':$vuetify.breakpoint.smAndDown}">
                        <v-col cols="12" sm="5" md="12" offset-sm="1" offset-md="0" order="2" order-md="1">
                            <div class="legend mt-5 mt-md-0">
                                <h3>Legend</h3>
                                <div>
                                    <p>Rarity</p>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Rarity</th>
                                            <th>Owned</th>
                                            <th>Available</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Yours</td>
                                            <td>
                                                <v-icon :color="hexColor.owned"
                                                        :style="'opacity:'+hexColor.opacity.claimed">
                                                    mdi-hexagon
                                                </v-icon>
                                            </td>
                                            <td>
                                                <v-icon :color="hexColor.owned"
                                                        :style="'opacity:'+hexColor.opacity.unclaimed">
                                                    mdi-hexagon
                                                </v-icon>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Normal</td>
                                            <td>
                                                <v-icon :color="hexColor.rarity.normal"
                                                        :style="'opacity:'+hexColor.opacity.claimed">mdi-hexagon
                                                </v-icon>
                                            </td>
                                            <td>
                                                <v-icon :color="hexColor.rarity.normal"
                                                        :style="'opacity:'+hexColor.opacity.unclaimed">mdi-hexagon
                                                </v-icon>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Rare</td>
                                            <td>
                                                <v-icon :color="hexColor.rarity.rare"
                                                        :style="'opacity:'+hexColor.opacity.claimed">
                                                    mdi-hexagon
                                                </v-icon>
                                            </td>
                                            <td>
                                                <v-icon :color="hexColor.rarity.rare"
                                                        :style="'opacity:'+hexColor.opacity.unclaimed">mdi-hexagon
                                                </v-icon>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Epic</td>
                                            <td>
                                                <v-icon :color="hexColor.rarity.epic"
                                                        :style="'opacity:'+hexColor.opacity.claimed">
                                                    mdi-hexagon
                                                </v-icon>
                                            </td>
                                            <td>
                                                <v-icon :color="hexColor.rarity.epic"
                                                        :style="'opacity:'+hexColor.opacity.unclaimed">mdi-hexagon
                                                </v-icon>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="5" md="12" order="1" order-md="2">
                            <div class="details pos-relative mt-5">
                                <div
                                    v-if="selectedLand && (selectedLand.status === 'AVAILABLE' || selectedLand.status === 'RESERVED')"
                                    class="pos-absolute label-price">
                                    <v-row class="mt-3" no-gutters>
                                        <v-col cols="5" offset="1" class="d-flex align-center">
                                            <v-img aspect-ratio="1" class="mr-1 ml-2" width="24" max-width="24"
                                                   src="/img/scroll_48.png" title="Wizarre SCRL" v-title/>
                                            <span>{{ selectedLand?.landPrice?.scrl.toLocaleString() }}</span>
                                        </v-col>
                                        <v-col cols="5" class=" d-flex align-center">
                                            <v-img aspect-ratio="1" class="mr-1 ml-5" width="24" max-width="24"
                                                   src="/img/tokens/usdt_48.png" title="USDT" v-title/>
                                            <span>{{ selectedLand?.landPrice?.usdt.toLocaleString() }}</span>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div class="details-ball d-flex flex-column align-center pos-relative">
                                    <v-progress-circular
                                        v-if="miniLandLoading"
                                        indeterminate
                                        :size="100"
                                        color="amber"
                                        class="mt-16"
                                    ></v-progress-circular>
                                    <div v-if="!selectedLand && !miniLandLoading">
                                        <p class="details-prompt">Click on the map</p>
                                    </div>


                                    <div v-if="selectedLand" class="w-100">
                                        <div class="mini-land-component">
                                            <div v-if="selectedLand.id">
                                                <router-link :to="{ name: 'Land', params: {id: selectedLand.id}}">
                                                    <mini-land
                                                        v-if="selectedLand"
                                                        :item="selectedLand"
                                                        popup-disabled
                                                    >
                                                    </mini-land>
                                                </router-link>
                                            </div>
                                            <div v-if="!selectedLand.id">
                                                <mini-land
                                                    class="mini-land-disabled"
                                                    v-if="selectedLand"
                                                    :item="selectedLand"
                                                >
                                                </mini-land>
                                            </div>
                                        </div>
                                        <div class="mini-land-text">
                                            <div class="name">{{ selectedLand.name }}</div>
                                            <div class="d-flex align-center justify-center">
                                                <icon-element :element="selectedLand.element"
                                                              img-size="18px" inline
                                                />
                                                {{ selectedLand.typeName }}
                                            </div>
                                            <div>
                                                {{ clicked.x }}x{{ clicked.y }}
                                                |
                                                <rarity-text :rarity="selectedLand.rarity"></rarity-text>
                                            </div>
                                        </div>
                                        <div class="mini-land-action d-flex flex-column justify-end">
                                            <v-btn
                                                v-if="['OWNED','NOT_AVAILABLE'].includes(selectedLand.status)"
                                                :to="{name: 'Land', params: {id: selectedLand.id}}"
                                                small
                                            >
                                                View
                                            </v-btn>
                                            <land-sale-dialog
                                                v-else-if="['AVAILABLE','RESERVED'].includes(selectedLand.status)"
                                                :land=selectedLand
                                            ></land-sale-dialog>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </v-col>
                    </div>
                </v-col>
            </v-row>
        </div>

    </v-container>
</template>

<script>
import MiniLand from "../components/assets/lands/mini-land";
import {apiServiceVue} from "../services/apiService.vue";
import RarityText from "../components/assets/lands/rarity-text";
import WorldMapService from "@/services/worldMapService";
import IconElement from "@/components/icon-element";
import MainButton from "@/components/ui/main-button";
import QueryHelper from "@/helpers/queryHelper";
import LandSaleDialog from "@/components/land-sale-dialog";

let worldMapService = null;

export default {
    components: {LandSaleDialog, RarityText, MiniLand, IconElement, MainButton},
    data() {
        return {
            routeName: null,
            landsRemainAmount: null,
            clicked: {
                x: parseInt(this.$route.query?.x ?? 0),
                y: parseInt(this.$route.query?.y ?? 0),
            },
            stats: {
                hexCounter: 0,
                claimed: 0,
                own: 0,
            },
            selectedLand: null,
            mapLoading: true,
            miniLandLoading: false,
            intervals: [],
            intervalLand: null,
        }
    },
    mounted: function () {
        this.routeName = this.$route.name;
        this.initMap();
        window.addEventListener('user-wallet-loaded', () => {
            this.reloadMapHexes(this.worldMapService, this.clicked);
        });

        window.addEventListener('map-click-hex-land', (event) => {
            const x = event.detail.x;
            const y = event.detail.y;
            if (x && y && !isNaN(x) && !isNaN(y)) {
                this.clicked.x = x;
                this.clicked.y = y;
                this.getLand(x, y);
            }
        });
        window.addEventListener('map-hex-stats', (event) => {
            this.stats = event.detail.stats;
        });
    },
    destroyed() {
        this.selectedLand = null;
        this.routeName = null;
        clearInterval(this.intervalLand);
        for (const interval of this.intervals) {
            clearInterval(interval);
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        isDebugEnv() {
            return 'development' === process.env.NODE_ENV;
        },
        hexColor() {
            return WorldMapService.getHexColors();
        },
        isSmallDesktopWidth() {
            return this.$vuetify.breakpoint.width < 1520;
        }
    },
    methods: {
        initMap() {
            this.mapLoading = true;
            const canvas = document.getElementById('canvas');
            this.worldMapService = new WorldMapService(this.isDebugEnv);
            this.worldMapService.initMapCanvas(canvas, 'map-img');
            this.reloadMapHexes(this.worldMapService, this.clicked);
            if (this.clicked.x && this.clicked.y) {
                this.getLand(this.clicked.x, this.clicked.y);
            }

            this.intervals.push(
                // Interval is for land sale
                // setInterval(() => {
                //     if (this.$route.name === this.routeName) {
                //         this.reloadMapHexes(this.worldMapService, this.clicked);
                //     }
                // }, 5 * 1000) // 5sec

                // Timeout is to avoid not-loading map bug
                setTimeout(() => {
                    if (this.$route.name === this.routeName) {
                        this.reloadMapHexes(this.worldMapService, this.clicked);
                    }
                }, 1000) // 1sec
            );
        },

        reloadMapHexes(worldMapService, activeHexPos) {
            let url = process.env.VUE_APP_MAP_SERVER_URL;
            if (this.isWalletConnected && this.web3.address) {
                url = url + '?owner=' + this.web3.address;
            }
            apiServiceVue.get(url)
                .then(res => {
                    const positions = res.data;
                    if (this.$route.name === this.routeName) {
                        this.landsRemainAmount = positions.filter((a) => !a.c).length;
                        worldMapService.drawHexes(positions, activeHexPos);
                        this.mapLoading = false;
                    }
                })
                .finally(() => {
                });
        },

        getLand(x, y) {
            this.miniLandLoading = true;
            this.selectedLand = null;
            this.refreshLandInfo(x, y);
            // this.intervalLandRefresh();
        },
        intervalLandRefresh() {
            // Enable for land sale
            // clearInterval(this.intervalLand);
            // this.intervalLand = setInterval(() => {
            //     if (this.selectedLand && ['AVAILABLE', 'RESERVED'].includes(this.selectedLand.status)) {
            //         this.refreshLandInfo(this.selectedLand.pos_x, this.selectedLand.pos_y);
            //     }
            // }, 5 * 1000) // 5sec
            //
            // this.intervals.push(this.intervalLand);
        },
        refreshLandInfo(x, y) {
            QueryHelper.setQueryParamsInUrl({x: x, y: y})
            const url = process.env.VUE_APP_MAP_SERVER_URL + '/' + x + '/' + y;
            apiServiceVue.get(url)
                .then(res => {
                    const nft = res.data;
                    if (nft) {
                        this.selectedLand = nft;
                    }
                })
                .finally(() => {
                    this.miniLandLoading = false;
                })
        }
    }
}
</script>

<style lang="scss" scoped>
$map-bg-color: #f0af65;
$legend-border-color: #b59365;

.label-price {
    top: 97%;
    left: 5%;
    width: 85%;
    height: 51px;
    background-image: url("/img/market/price-plate.png");
    background-size: 100% 100%;
    z-index: 0;
}

.container {
    width: 100%;
    height: 100%;
}

.map-container {
    width: 100%;
    height: auto;
    aspect-ratio: 1176/702;
    position: relative;
    text-align: center;
}

.map-loading {
    width: 80%;
    height: 80%;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: $map-bg-color;
}

.map-frame-elem-l,
.map-frame-elem-t,
.map-frame-elem-r,
.map-frame-elem-b,
{
    background: url('/img/map/elem/map-box-frame.png') 0 0 no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;

    &.map-frame-elem-l,
    &.map-frame-elem-r {
        width: 4.4%;
        height: 100%;
    }


    &.map-frame-elem-r {
        left: auto;
        right: 0;
        background-position: 100% 0;
    }

    &.map-frame-elem-t,
    &.map-frame-elem-b {
        width: 100%;
        height: 7.5%;
    }

    &.map-frame-elem-b {
        top: auto;
        bottom: 0;
        background-position: 0 100%;
    }
}

.map-frame-title {
    width: auto;
    height: 12%;
    aspect-ratio: 792/89;
    background: url('/img/map/elem/map-title-box.png') 0 0 no-repeat;
    background-size: cover;
    position: absolute;
    margin: auto;
    top: 1%;
    left: 0;
    right: 0;

    h1, h2, h3 {
        line-height: 100%;
        margin-top: -5px;
    }
}

.map-frame-wind-rose {
    width: 18%;
    height: auto;
    aspect-ratio: 215/216;
    background: url('/img/map/elem/wind-rose.png') 0 0 no-repeat;
    background-size: cover;
    position: absolute;
    left: 1%;
    bottom: 1%;
}

.box-remaining {
    width: 20%;
    height: 21%;
    background: url('/img/map/elem/legend-box-mobile.svg') 0 0 no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 10px;
    right: 0;
    color: #000;

    .head-icon {
        display: inline-block;
        animation: MinorWobble 8s linear infinite;
    }

    .head-text {
        margin-top: 20% !important;
    }
}

#canvas {
    width: 100%;
    height: 90%;
    background: $map-bg-color;
    margin: auto;
    z-index: 0;
}

.map-stats {
    position: absolute;
    top: 60px;
    right: 60px;
    color: #000;
    line-height: 18px;
}

.legend {
    width: 100%;
    height: auto;
    min-height: 260px !important;
    padding: 10px 20px;
    aspect-ratio: 227/255;
    background: url('/img/map/elem/legend-box.png') 0 0 no-repeat;
    background-size: cover;
    text-align: center;
    color: #000;

    h3 {
        width: 80%;
        margin: auto;
        padding: 10px 0;
        border-bottom: 5px solid $legend-border-color;
    }

    table {
        width: 100%;

        th {
            border-bottom: 1px solid #fff;
        }

        td, th {
            text-align: center;

            &:first-child {
                text-align: left;
            }
        }
    }
}

.details {
    .details-ball {
        width: 100%;
        height: auto;
        aspect-ratio: 274/329;
        background: url('/img/map/elem/crystal-ball.png') 0 0 no-repeat;
        background-size: cover;
        text-align: center;

        .details-prompt {
            margin-top: 100%;
        }
    }
}

.mini-land-text {
    margin: auto;
    margin-top: -10px;
    font-size: 12px;
    line-height: 16px;
    position: relative;

    .name {
        font-size: 16px;
        font-weight: bold;
    }
}

.mini-land-action {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7%;
    margin-left: 10%;
    margin-right: 15%;
}

.mini-land-component {
    width: 100%;
    margin-top: 30px;
}

::v-deep .mini-item {
    .item-box {
        width: 100% !important;
        height: auto !important;
        display: flex;
        flex-direction: column;
        background: none !important;

        .item-header {
            display: none !important;
        }

        .item-content {
            img.thumbnail-img {
                width: 55%;
                height: auto;
                aspect-ratio: 1;
                max-height: none;
                margin: auto;
                margin-left: 4%;
            }
        }
    }
}

::v-deep .mini-land-disabled {
    &.mini-item {
        &:hover .item-hover {
            display: none !important;
        }
    }
}

::v-deep .mini-land {
    width: 100%;

    .image {
        display: flex;
        justify-content: center;
        margin: auto;

        img {
            width: 100%;
            height: auto;
            margin: 10px auto 0 auto;
        }
    }
}


::v-deep .mini-land:hover {
    cursor: inherit !important;
    box-shadow: none !important;
}

@media only screen and (min-width: $lg) {
    .mini-land-text {
        margin-top: 0;
    }

}

@media only screen and (min-width: $sm) {
    .box-remaining {
        .head-text {
            margin-top: 15%;
        }
    }
}

@media only screen and (max-width: $xs) {
    h1, h2, h3 {
        font-size: 100%;
    }
}

@keyframes MinorWobble {
    0%, 100% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(-5deg);
    }
}

</style>
