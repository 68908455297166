var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "main-button",
        { attrs: { "on-click": _vm.openPopup, small: true, glow: "" } },
        [_vm._v(" Buy ")]
      ),
      _vm.isWalletConnected
        ? _c(
            "dialog-box",
            {
              attrs: {
                model: _vm.popupOpen,
                title: `Buy ${_vm.land.name}`,
                width: "700",
              },
              on: {
                dialogClosed: function ($event) {
                  _vm.popupOpen = !_vm.popupOpen
                },
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-1" },
                    [
                      _vm.land.status === "AVAILABLE"
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "w-100",
                              attrs: {
                                dense: "",
                                outlined: "",
                                text: "",
                                type: "success",
                              },
                            },
                            [_vm._v(" Land is available for sale. ")]
                          )
                        : _vm.land.status === "RESERVED"
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "w-100",
                              attrs: {
                                dense: "",
                                outlined: "",
                                text: "",
                                type: "warning",
                              },
                            },
                            [
                              _vm._v(
                                " Land is currently reserved by someone else. "
                              ),
                            ]
                          )
                        : _vm.land.status === "OWNED"
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "w-100",
                              attrs: {
                                dense: "",
                                outlined: "",
                                text: "",
                                type: "error",
                              },
                            },
                            [_vm._v(" Land is already claimed. ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "5" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mb-4 d-flex justify-center justify-sm-start",
                            },
                            [
                              _c("mini-land", {
                                attrs: { item: _vm.land, "popup-disabled": "" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "section-details pt-2 mb-4 d-flex justify-center justify-sm-end",
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-row", [_c("p", [_vm._v("Type: ")])]),
                                  _c("v-row", [_c("p", [_vm._v("Coords: ")])]),
                                  _c("v-row", [_c("p", [_vm._v("Element: ")])]),
                                  _c("v-row", [_c("p", [_vm._v("Rarity: ")])]),
                                  _c("v-row", [_c("p", [_vm._v("Power: ")])]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-row", [
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.land.typeName)),
                                      ]),
                                    ]),
                                  ]),
                                  _c("v-row", [
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.land.pos_x) +
                                            "x" +
                                            _vm._s(_vm.land.pos_y)
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c("v-row", [
                                    _c("p", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-capitalize",
                                          class:
                                            "text-element-" +
                                              _vm.land.element || "",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "mdi-" + _vm._s(_vm.elementIcon)
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(_vm.land.element) + " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                  _c("v-row", [
                                    _c(
                                      "p",
                                      [
                                        _c("rarity-text", {
                                          attrs: { rarity: _vm.land.rarity },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-row", [
                                    _c("p", [
                                      _vm.land.type
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.land.description)
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center" },
                        [
                          _c("h3", [_vm._v("Bonus gifts")]),
                          _c(
                            "v-row",
                            { staticClass: "bonus-enhancements" },
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c("h4", { staticClass: "mb-2" }, [
                                  _vm._v("3x"),
                                ]),
                                _c("img", {
                                  directives: [
                                    { name: "title", rawName: "v-title" },
                                  ],
                                  attrs: {
                                    src: "https://cdn.wizarre.io/img/e/150/0.png",
                                    alt: "Ephemeral Wizard",
                                    title: "3x Ephemeral Wizard",
                                  },
                                }),
                              ]),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c("h4", { staticClass: "mb-2" }, [
                                  _vm._v("6x"),
                                ]),
                                _c("img", {
                                  directives: [
                                    { name: "title", rawName: "v-title" },
                                  ],
                                  attrs: {
                                    src: "https://cdn.wizarre.io/img/e/150/27.png",
                                    alt: "Yummy Yummy",
                                    title: "6x Yummy Yummy",
                                  },
                                }),
                              ]),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c("h4", { staticClass: "mb-2" }, [
                                  _vm._v("9x"),
                                ]),
                                _c("img", {
                                  directives: [
                                    { name: "title", rawName: "v-title" },
                                  ],
                                  attrs: {
                                    src: "https://cdn.wizarre.io/img/e/150/26.png",
                                    alt: "Jumplegs",
                                    title: "9x Jumplegs",
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.messages.value.length === 0 &&
                  ["AVAILABLE"].includes(_vm.land.status)
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "hidden-xs-only",
                              attrs: { cols: "1" },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "price-row d-flex align-center",
                                },
                                [
                                  _c("v-img", {
                                    directives: [
                                      { name: "title", rawName: "v-title" },
                                    ],
                                    staticClass: "mr-2",
                                    attrs: {
                                      "aspect-ratio": "1",
                                      "max-width": "24",
                                      src: "/img/scroll_48.png",
                                      title: "Wizarre SCRL",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "price-row d-flex align-center",
                                },
                                [
                                  _c("v-img", {
                                    directives: [
                                      { name: "title", rawName: "v-title" },
                                    ],
                                    staticClass: "mr-2",
                                    attrs: {
                                      "aspect-ratio": "1",
                                      "max-width": "24",
                                      src: "/img/tokens/usdt_48.png",
                                      title: "USDT",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "5", sm: "3" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "price-row d-flex align-center justify-end",
                                },
                                [
                                  _c("h4", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.land?.landPrice?.scrl.toLocaleString()
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "price-row d-flex align-center justify-end",
                                },
                                [
                                  _c("h4", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.land?.landPrice?.usdt.toLocaleString()
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3", sm: "2" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "price-row d-flex align-center",
                                },
                                [_c("h4", [_vm._v("SCRL")])]
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "price-row d-flex align-center",
                                },
                                [_c("h4", [_vm._v("USDT")])]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3", sm: "6" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "price-row d-flex align-center",
                                },
                                [
                                  _c(
                                    "main-button",
                                    {
                                      directives: [
                                        { name: "title", rawName: "v-title" },
                                      ],
                                      attrs: {
                                        "on-click": _vm.buyForSCRL,
                                        loading: _vm.buyInProgress,
                                        disabled: !_vm.hasBalanceToBuyInSCRL,
                                        title: _vm.hasBalanceToBuyInSCRL
                                          ? ""
                                          : _vm.messages.notEnoughSCRL,
                                      },
                                    },
                                    [_vm._v(" Buy ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "price-row d-flex align-center",
                                },
                                [
                                  _c(
                                    "main-button",
                                    {
                                      directives: [
                                        { name: "title", rawName: "v-title" },
                                      ],
                                      attrs: {
                                        "on-click": _vm.buyForUSDT,
                                        loading: _vm.buyInProgress,
                                        disabled: !_vm.hasBalanceToBuyInUSDT,
                                        title: _vm.hasBalanceToBuyInUSDT
                                          ? ""
                                          : _vm.messages.notEnoughUSDT,
                                      },
                                    },
                                    [_vm._v(" Buy ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.messages.value.length > 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "w-100 mt-5",
                              attrs: {
                                type: _vm.messages.type,
                                dense: "",
                                outlined: "",
                                text: "",
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.messages.value) + " "),
                              _vm.messages.type === "info"
                                ? _c("v-progress-linear", {
                                    attrs: { color: "blue", indeterminate: "" },
                                  })
                                : _vm._e(),
                              _vm.messages.type === "error"
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-2 d-block",
                                      attrs: { color: "red" },
                                      on: { click: _vm.openPopup },
                                    },
                                    [_vm._v(" Retry ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : !_vm.isWalletConnected
        ? _c(
            "dialog-box",
            {
              attrs: {
                model: _vm.popupOpen,
                title: `Buy ${_vm.land.name}`,
                width: "1000",
              },
              on: {
                dialogClosed: function ($event) {
                  _vm.popupOpen = !_vm.popupOpen
                },
              },
            },
            [_c("connect-wallet", { attrs: { disableImage: "" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }