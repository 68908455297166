import paper from "paper";
import * as Hammer from "hammerjs";

const MAP = {
    // Feel free to adjust these
    initialZoom: 0.7,
    startPositionAlter: {x: 0.02, y: -0.1},
    startPositionForMobile: {x: -200, y: -150},
    zoomSpeed: 0.2,
    zoomLimits: {max: 22, min: 0.1},

    // Below vars are tied together
    size: 10,
    mapScale: 0.2,
    mapBgScale: 0.58,
    mapHexYPosCorrection: 0,
    mapHexXPosCorrection: 1,
    mapPosition: {x: 500, y: 350},
}

const HEX_COLORS = {
    opacity: {
        unclaimed: 0.8,
        claimed: 0.3,
    },
    selected: '#000',
    owned: '#00E1B6FF',
    visible: '#ccc',
    rarity: {
        normal: '#fff',
        rare: '#6495ED', //cornflowerblue
        epic: '#78498d', //rebeccapurple
    }
}

export default class WorldMapService {
    paperScope;
    hexGroup;
    isDebugEnv = false;

    constructor(isDebugEnv = false) {
        this.isDebugEnv = isDebugEnv;
    }

    static getHexColors() {
        return HEX_COLORS;
    }

    initMapCanvas(canvasElement, mapImgId) {
        this.paperScope = new paper.PaperScope();
        this.paperScope.setup('canvas');

        const hammer = new Hammer(canvasElement);
        hammer.get('pinch').set({enable: true});
        hammer.on('pinch', (event) => {
            this.paperScope.view.zoom = event.scale;
            // this.paperScope.view.zoom = this.paperScope.view.zoom * newScale;
        });

        const toolPan = new paper.Tool()
        toolPan.activate()

        this.paperScope.paper.project.importSVG('/img/map/map-border-bnw.svg', (map) => {
            map.scale(MAP.mapScale);
            map.position = new paper.Point(MAP.mapPosition.x, MAP.mapPosition.y);
            map.opacity = 0;
            const mapPath = map.children[1];

            const mapImgRaster = new paper.Raster(mapImgId);
            mapImgRaster.position = new paper.Point(MAP.mapPosition.x, MAP.mapPosition.y);
            mapImgRaster.scale(MAP.mapBgScale);
            mapImgRaster.sendToBack();

            let startPosition = {
                x: parseInt(canvasElement.width * MAP.startPositionAlter.x),
                y: parseInt(canvasElement.height * MAP.startPositionAlter.y),
            };
            if (window.innerWidth < 560) {
                startPosition = {
                    x: MAP.startPositionForMobile.x,
                    y: MAP.startPositionForMobile.y,
                };
            }

            this.paperScope.view.translate(new paper.Point(startPosition.x, startPosition.y));
            this.paperScope.view.zoom = MAP.initialZoom;

            toolPan.onMouseDown = (event) => {
                this.mouseClickDownPoint = event.event;
            };
            toolPan.onMouseUp = (event) => {
                if ('MouseEvent' === event.event.constructor.name) {
                    // Check whether clicked or map moved
                    if (event.event === null || parseInt(event.event.clientX) !== parseInt(this.mouseClickDownPoint.clientX) || parseInt(event.event.clientY) !== parseInt(this.mouseClickDownPoint.clientY)) {
                        return
                    }
                }
                if ('TouchEvent' === event.event.constructor.name) {
                    // Check whether clicked or map moved
                    const singleTouch = event.event.changedTouches[0];
                    if (event.event === null || parseInt(singleTouch.clientX) !== parseInt(this.mouseClickDownPoint.changedTouches[0].clientX) || parseInt(singleTouch.clientY) !== parseInt(this.mouseClickDownPoint.changedTouches[0].clientY)) {
                        return
                    }
                }

                const point = event.point;

                this.onMouseUp(point, this.paperScope, mapPath, MAP.size);
            };

            toolPan.onMouseDrag = (event) => {
                const delta = event.downPoint.subtract(event.point);
                this.paperScope.view.translate(delta.multiply(-1));
            }

            canvasElement.addEventListener('wheel', event => {
                this.onZoom(event);
            });

            if (this.isDebugEnv) {
                this.displayFPS();
            }
        })
    }

    drawHexes(hexPositions, activeHexPos = null) {
        // Clear hexes
        if (this.hexGroup) {
            this.hexGroup.remove();
        }

        const pointO = new this.paperScope.Point(0, 0);
        let hexO = new this.paperScope.Path.RegularPolygon(pointO, 6, MAP.size / 2 + 1);
        hexO.fillColor = HEX_COLORS.visible;
        hexO.opacity = HEX_COLORS.opacity.unclaimed;
        hexO.aX = 0;
        hexO.aY = 0;
        this.hexGroup = new this.paperScope.Group([hexO]);

        const tmpStats = {
            hexCounter: 0,
            claimed: 0,
            own: 0
        };
        const initPos = (x, y) => {
            return {
                x: x * (MAP.size + MAP.mapHexXPosCorrection) + (y % 2 ? (MAP.size + MAP.mapHexXPosCorrection) / 2 : 0),
                y: y * (MAP.size + MAP.mapHexYPosCorrection),
            }
        }

        for (let i = 0; i < hexPositions.length; i++) {
            const curHex = hexPositions[i];
            const x = curHex.x;
            const y = curHex.y;
            const left = initPos(x, y).x;
            const top = initPos(x, y).y;
            const point = new this.paperScope.Point(left, top);

            let hex = hexO.clone();
            hex.position = point;
            hex.aX = x;
            hex.aY = y;
            if (curHex.c) {
                hex.opacity = HEX_COLORS.opacity.claimed;
                tmpStats.claimed++
            }
            if (curHex.own) {
                hex.fillColor = HEX_COLORS.owned;
                tmpStats.own++;
            } else {
                if ('r' === curHex.r) {
                    hex.fillColor = HEX_COLORS.rarity.rare;
                }
                if ('e' === curHex.r) {
                    hex.fillColor = HEX_COLORS.rarity.epic;
                }
            }
            tmpStats.hexCounter++;
        }
        hexO.remove();

        // Active hex
        if (activeHexPos && activeHexPos.x) {
            if (this.selectedHex) {
                this.selectedHex.remove();
            }
            const pos = initPos(activeHexPos.x, activeHexPos.y);
            const hexS = new this.paperScope.Path.RegularPolygon(new paper.Point(pos.x, pos.y), 6, MAP.size / 2 + 1);
            hexS.fillColor = HEX_COLORS.selected;
            this.selectedHex = hexS;
        }

        const event = new CustomEvent('map-hex-stats', {
            detail: {
                stats: tmpStats
            }
        });
        window.dispatchEvent(event);
    }

    onMouseUp(point, project, mapPath, size) {
        const hitResult = this.paperScope.paper.project.hitTest(point, {
            segments: false,
            stroke: true,
            fill: true,
            tolerance: 2
        });
        if (hitResult && 'fill' === hitResult.type) {
            const path = hitResult.item;
            if (path === mapPath) {
                return;
            }
            if (this.selectedHex) {
                this.selectedHex.remove();
            }

            const hexS = new this.paperScope.Path.RegularPolygon(new paper.Point(hitResult.item.position.x, hitResult.item.position.y), 6, size / 2 + 1);
            hexS.fillColor = HEX_COLORS.selected;
            this.selectedHex = hexS;

            const event = new CustomEvent('map-click-hex-land', {
                detail: {
                    x: hitResult.item.aX,
                    y: hitResult.item.aY
                }
            });
            window.dispatchEvent(event);
        }
        this.mouseClickDownPoint = null;
    }

    onZoom(event) {
        const paperScope = this.paperScope;
        const oldZoom = paperScope.view.zoom;
        let newZoom;

        if (event.deltaY < 0) {
            newZoom = paperScope.view.zoom * (1 + MAP.zoomSpeed);
        } else {
            newZoom = paperScope.view.zoom * (1 - MAP.zoomSpeed);
        }

        // Limit zoom
        if (newZoom > MAP.zoomLimits.max || newZoom < MAP.zoomLimits.min) {
            newZoom = oldZoom;
        }

        const beta = oldZoom / newZoom;

        const mousePosition = new paperScope.Point(event.offsetX, event.offsetY);

        //viewToProject: gives the coordinates in the Project space from the Screen Coordinates
        const viewPosition = paperScope.view.viewToProject(mousePosition);

        const mpos = viewPosition;
        const ctr = paperScope.view.center;

        const pc = mpos.subtract(ctr);
        const offset = mpos.subtract(pc.multiply(beta)).subtract(ctr);

        paperScope.view.zoom = newZoom;
        paperScope.view.center = paperScope.view.center.add(offset);

        event.preventDefault();
        paperScope.view.draw();
    }

    displayFPS() {
        window.requestAnimFrame = (function () {
            return window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.ieRequestAnimationFrame ||
                function (callback) {
                    window.setTimeout(callback, 1000 / 60);
                };
        })();
        const fpsElement = document.getElementById("fps");
        let then = Date.now() / 1000;  // get time in seconds
        const render = function () {
            const now = Date.now() / 1000;  // get time in seconds
            // compute time since last frame
            const elapsedTime = now - then;
            then = now;
            // compute fps
            const fps = 1 / elapsedTime;
            fpsElement.innerText = fps.toFixed(2);

            requestAnimFrame(render);
        };
        render();
    }
}

